import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from '@mui/material'
import { Block, Check, StarBorder } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import {
  moderateAcceptCommentAction,
  moderateRejectCommentAction,
  moderateBanUserAction
} from '../../actions/moderate-actions'
import { commentProps } from '../prop-types'
import { palette as ylePalette } from '../../styles/colors'
import { formatRelativeTimestamp } from '../../utils/date-utils'
import { REJECT_REASONS } from '../../utils/reject-reasons'
import { settingsSelector } from '../../selectors/settings-selector'
import { BanDialog } from '../BanDialog/BanDialog'
import { RejectButton } from '../RejectButton/RejectButton'
import { bool } from 'prop-types'
import { useKeyboardShortcut } from '../../utils/use-keyboard-shortcut'
import { chatRoutes } from '../../constants/routes'

const useStyles = makeStyles(({ palette, spacing }) => ({
  card: {
    marginBottom: spacing(1),
    width: '100%'
  },
  quote: {
    borderLeft: [[spacing(0.5), 'solid', palette.primary.main]],
    paddingLeft: spacing(2),
    marginBottom: spacing(1)
  },
  blockButton: {
    textTransform: 'none',
    paddingLeft: '11px'
  },
  starIcon: {
    color: ylePalette.green.dark,
    marginBottom: '3px'
  },
  authorContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  authorText: {
    fontWeight: 600,
    paddingRight: '8px'
  },
  contentText: {
    'overflow-wrap': 'anywhere'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    marginRight: '1em',
    marginBottom: '1em',
    marginLeft: '.5em',
    rowGap: '8px'
  },
  buttonIcon: {
    marginBottom: '3px'
  },
  rejectButton: {
    flex: 1,
    minHeight: '40px',
    maxWidth: '150px',
    minWidth: '120px !important',
    fontSize: '10px !important'
  }
}))

export function Comment({ comment, useKeyboardShortcuts = false }) {
  const { author, content, createdAt, id, quote, topicId } = comment
  const location = useLocation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { language } = useSelector(settingsSelector)
  const [dialogOpen, setDialogOpen] = useState(false)
  const accept = () => dispatch(moderateAcceptCommentAction({ chatId: topicId, commentId: id }))
  const reject = reason =>
    dispatch(moderateRejectCommentAction({ chatId: topicId, commentId: id, reason }))
  const acceptAndStar = () =>
    dispatch(moderateAcceptCommentAction({ chatId: topicId, commentId: id, isStarred: true }))
  const openDialog = () => setDialogOpen(true)
  const onCancelDialog = () => {
    setDialogOpen(false)
  }
  const onConfirmDialog = ({ banReason, banDescription }) => {
    setDialogOpen(false)
    dispatch(
      moderateBanUserAction({
        chatId: topicId,
        commentId: id,
        reason: banReason,
        description: banDescription,
        isModeration: location.pathname.endsWith(chatRoutes.moderation)
      })
    )
  }
  useKeyboardShortcut(useKeyboardShortcuts, [
    ['d', () => reject('TERMS_OR_DISCUSSION_RULES_VIOLATION')],
    ['f', accept]
  ])

  return (
    <Card classes={{ root: classes.card }}>
      <CardContent>
        {quote && (
          <div className={classes.quote}>
            <Typography variant="body1" className={classes.authorText}>
              {quote.author}
            </Typography>
            <Typography variant="body1" className={classes.contentText}>
              {quote.content}
            </Typography>
          </div>
        )}
        <div className={classes.authorContainer}>
          <Typography variant="body1" className={classes.authorText}>
            {author}
          </Typography>
          <Typography variant="body1">{formatRelativeTimestamp(createdAt)}</Typography>
          <Button
            variant="text"
            classes={{ root: classes.blockButton }}
            endIcon={<Block />}
            onClick={openDialog}
          >
            Ban user
          </Button>
        </div>
        <Typography variant="body1" className={classes.contentText}>
          {content}
        </Typography>
      </CardContent>
      <CardActions className={classes.buttonContainer}>
        <Button
          variant="text"
          className="acceptAndStar"
          color="primary"
          onClick={acceptAndStar}
          startIcon={<StarBorder />}
          classes={{ startIcon: classes.starIcon }}
          style={{ fontSize: '16px', color: ylePalette.green.dark }}
        >
          Star
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Check />}
          onClick={accept}
          classes={{ startIcon: classes.buttonIcon }}
          style={{
            fontSize: '16px',
            marginHorizontal: '16px',
            backgroundColor: ylePalette.green.dark,
            color: ylePalette.white.normal
          }}
        >
          {useKeyboardShortcuts ? 'Accept (F)' : 'Accept'}
        </Button>
      </CardActions>
      <CardActions className={classes.buttonContainer}>
        <div>Reject:</div>
        <RejectButton
          id={comment.id}
          text={language === 'fi' ? 'Kaikki' : 'Alla'}
          onReject={reject}
        />
        {[
          'NEGATIVE_EFFECTS_ON_CIVIC_DISCOURSE_OR_ELECTIONS',
          'ILLEGAL_OR_HARMFUL_SPEECH',
          'VIOLENCE',
          'TERMS_OR_DISCUSSION_RULES_VIOLATION'
        ].map(reason => (
          <Button
            key={`reject-${reason}`}
            onClick={() => reject(reason)}
            className={classes.rejectButton}
            size="small"
            variant={reason === 'TERMS_OR_DISCUSSION_RULES_VIOLATION' && useKeyboardShortcuts ? 'contained' : "outlined"}
            color="error"
          >
            {REJECT_REASONS(language)[reason].short}
            {reason === 'TERMS_OR_DISCUSSION_RULES_VIOLATION' && useKeyboardShortcuts && ' (D)'}
          </Button>
        ))}
      </CardActions>
      <BanDialog
        open={dialogOpen}
        onCancel={onCancelDialog}
        onConfirm={onConfirmDialog}
        author={author}
        language={language}
      />
    </Card>
  )
}

Comment.propTypes = {
  comment: commentProps.isRequired,
  useKeyboardShortcuts: bool
}
