import * as actions from '../actions/action-types'

export default function (state, action) {
  switch (action.type) {
    case actions.SETTINGS_SET_LANGUAGE: {
      return Object.freeze({
        ...state,
        language: action.language
      })
    }

    case actions.SETTINGS_TOGGLE_SHOW_MODAL: {
      return Object.freeze({
        ...state,
        show: !state.show
      })
    }

    default:
      return state
  }
}
