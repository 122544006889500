import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Tab, Tabs } from '@mui/material'
import { bool, func } from 'prop-types'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom'
import { ChatEditTab } from './ChatEditor/ChatEditTab'
import { Moderate } from './Moderate/Moderate'
import { PollList } from './Polls/PollList'
import { ChatStats } from './ChatStats/ChatStats'
import { Messages } from './Messages/Messages'
import { chatProps } from './prop-types'
import { getToggleAcceptedRadioButtonAction } from '../actions/comments-actions'
import { moderationStateSelector } from '../selectors/moderate-selector'
import { moderateStateRequestAction } from '../actions/moderate-actions'
import { chatRoutes } from '../constants/routes'
import { ChatFlagged } from './Flagged/Flagged'

const useStyles = makeStyles(() => ({
  hidden: {
    visibility: 'hidden'
  },
  wrap: {
    flexWrap: 'wrap'
  }
}))

export function ChatTabs({ chat, isOperator }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { url } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  const moderateState = useSelector(moderationStateSelector, shallowEqual)

  const navigateToMessages = accepted => {
    dispatch(getToggleAcceptedRadioButtonAction(accepted))
    history.push(`${url}${chatRoutes.messages}`)
  }

  useEffect(() => {
    dispatch(moderateStateRequestAction({ chatId: chat.id }))
  }, [chat.id])

  return (
    <>
      <Tabs
        value={
          Object.values(chatRoutes).find(path => location.pathname.endsWith(path)) ||
          chatRoutes.moderation
        }
        classes={{ centered: classes.wrap }}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab
          value={chatRoutes.settings}
          to={`${url}${chatRoutes.settings}`}
          component={Link}
          label="Settings"
          className={isOperator ? '' : classes.hidden}
        />
        <Tab
          value={chatRoutes.moderation}
          to={`${url}${chatRoutes.moderation}`}
          component={Link}
          label={`Moderation ${moderateState ? `(${moderateState.queued})` : ''}`}
        />
        <Tab
          value={chatRoutes.flagged}
          to={`${url}${chatRoutes.flagged}`}
          component={Link}
          label={`Flagged ${moderateState ? `(${moderateState.flagged})` : ''}`}
        />
        <Tab
          value={chatRoutes.polls}
          to={`${url}${chatRoutes.polls}`}
          component={Link}
          label="Polls"
        />
        <Tab
          value={chatRoutes.stats}
          to={`${url}${chatRoutes.stats}`}
          component={Link}
          label="Stats"
        />
        <Tab
          value={chatRoutes.messages}
          to={`${url}${chatRoutes.messages}`}
          component={Link}
          label={`Messages ${
            moderateState ? `(${moderateState.accepted + moderateState.rejected})` : ''
          }`}
        />
      </Tabs>
      <TabContent chat={chat} navigateToMessages={navigateToMessages} isOperator={isOperator} />
    </>
  )
}

ChatTabs.propTypes = {
  chat: chatProps.isRequired,
  isOperator: bool.isRequired
}

function TabContent({ chat, navigateToMessages, isOperator }) {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}${chatRoutes.settings}`} render={() => <ChatEditTab chat={chat} />} />
      <Route
        path={`${path}${chatRoutes.moderation}`}
        render={() => <Moderate chat={chat} navigateToMessages={navigateToMessages} />}
      />
      <Route path={`${path}${chatRoutes.flagged}`} render={() => <ChatFlagged chat={chat} />} />
      <Route path={`${path}${chatRoutes.polls}`} render={() => <PollList chatId={chat.id} />} />
      <Route path={`${path}${chatRoutes.stats}`} render={() => <ChatStats chat={chat} />} />
      <Route path={`${path}${chatRoutes.messages}`} render={() => <Messages chatId={chat.id} />} />
      <Redirect
        to={isOperator ? `${path}${chatRoutes.settings}` : `${path}${chatRoutes.moderation}`}
      />
    </Switch>
  )
}

TabContent.propTypes = {
  chat: chatProps.isRequired,
  navigateToMessages: func.isRequired,
  isOperator: bool.isRequired
}
