/* istanbul ignore file */

import { combineReducers } from 'redux'
import chats from './chats-reducer'
import moderate from './moderate-reducer'
import polls from './polls-reducer'
import snackbar from './snackbar-reducer'
import user from './user-reducer'
import comments from './comments-reducer'
import stats from './stats-reducer'
import banned from './banned-reducer'
import userList from './user-list-reducer'
import emoji from './emoji-reducer'
import settings from './settings-reducer'
import flagged from './flagged-reducer'
import { initialState as defaultInitialState } from './initial-state'

export default function (initialState = defaultInitialState) {
  return combineReducers({
    config: () => initialState.config,
    user: (state = initialState.user, action) => user(state, action),
    chats: (state = initialState.chats, action) => chats(state, action),
    moderate: (state = initialState.moderate, action) => moderate(state, action),
    polls: (state = initialState.polls, action) => polls(state, action),
    stats: (state = initialState.stats, action) => stats(state, action),
    snackbar: (state = initialState.snackbar, action) => snackbar(state, action),
    comments: (state = initialState.comments, action) => comments(state, action),
    banned: (state = initialState.banned, action) => banned(state, action),
    userList: (state = initialState.userList, action) => userList(state, action),
    emoji: (state = initialState.emoji, action) => emoji(state, action),
    settings: (state = initialState.settings, action) => settings(state, action),
    flagged: (state = initialState.flagged, action) => flagged(state, action),
  })
}
