import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { Accordion, AccordionDetails, AccordionSummary, Badge, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Navigation } from '../components/Navigation/Navigation'
import { useDispatch } from 'react-redux'
import { getFlaggedCountsAction } from '../actions/flagged-actions'
import { flaggedCountsSelector } from '../selectors/flagged-selector'
import { useSelector } from 'react-redux'
import { formatRelativeTimestamp } from '../utils/date-utils'
import { Flagged } from '../components/Flagged/Flagged'
import { CenteredSpinner } from '../components/CenteredSpinner'

const InfoContainer = styled('div')`
  margin-right: 12px;
  display: flex;
`

const SummaryContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const StyledBadge = styled(Badge)`
  position: relative;
  top: 12px;
  right: 18px;
`

const Container = styled('div')`
  max-width: 800px;
`

function Info({ flaggedCount }) {
  return (
    <InfoContainer>
      <StyledBadge badgeContent={flaggedCount.flaggedComments} color="primary" />
      <Typography variant="body1">
        {formatRelativeTimestamp(flaggedCount.lastNoticeCreatedAt)}
      </Typography>
    </InfoContainer>
  )
}

export function FlaggedPage() {
  const dispatch = useDispatch()
  const flaggedCounts = useSelector(flaggedCountsSelector)

  useEffect(() => {
    dispatch(getFlaggedCountsAction())
  }, [])

  return (
    <Navigation titleText="Flagged comments">
      <Container>
        { flaggedCounts === null && <CenteredSpinner />}
        { flaggedCounts !== null && flaggedCounts.length === 0 &&
          <Typography sx={{ marginTop: 5 }} variant="body1">
            No flagged comments. Well done!
          </Typography>
        }
        { flaggedCounts !== null && flaggedCounts.map((flaggedCount) => (
          <Accordion key={flaggedCount.topicId}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <SummaryContainer>
                  <Typography variant="body1">{flaggedCount.title}</Typography>
                  <Info flaggedCount={flaggedCount} />
                </SummaryContainer>
            </AccordionSummary>
            <AccordionDetails>
              <Flagged chatId={flaggedCount.topicId} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Navigation>
  )
}
