import React from 'react'
import { Badge, Divider, Drawer, List, ListItem, Toolbar } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { bool } from 'prop-types'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/system'
import { AdminPanelSettings, Bookmark, Chat, Flag, Group } from '@mui/icons-material'
import { routes } from '../../constants/routes'
import { isOperatorSelector, isModeratorSelector } from '../../selectors/user-selector'
import { chatProps } from '../prop-types'
import { configSelector } from '../../selectors/config-selector'
import { flaggedCountsSelector, flaggedTotalCountSelector } from '../../selectors/flagged-selector'

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    width: theme.drawer.width
  },
  paper: {
    width: theme.drawer.width,
    boxSizing: 'border-box'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.dark,
    display: 'flex',
    alignItems: 'center'
  },
  divider: {
    marginTop: 4,
    marginBottom: 4
  }
}))

const Item = styled(ListItem)(() => ({
  paddingLeft: 16
}))

const NavIcon = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(2)
}))

export function SideDrawer({ chat, isOpen }) {
  const classes = useStyles()

  const isModerator = useSelector(isModeratorSelector)
  const isOperator = useSelector(isOperatorSelector)
  const flaggedCount = useSelector(flaggedTotalCountSelector)
  const { chatUrl } = useSelector(configSelector)

  const commonRoutes = [{ name: 'Users', route: routes.users, icon: <Group /> }]

  const moderatorRoutes = isModerator
    ? [{ name: 'All chats', route: routes.chatList, icon: <Chat /> }]
    : []

  const operatorRoutes = isOperator
    ? [{ name: 'Banned users', route: routes.bannedUsersList, icon: <AdminPanelSettings /> },
       { name: 'Flagged messages', route: routes.flagged, icon: <Badge badgeContent={flaggedCount} color="primary"><Flag /></Badge> }]
    : []

  const allRoutes = [...moderatorRoutes, ...commonRoutes, ...operatorRoutes]

  const drawerContent = (
    <>
      <Toolbar />
      <List>
        <>
          {allRoutes.map(route => (
            <Item key={route.name}>
              <NavLink className={classes.link} to={route.route}>
                <NavIcon>{route.icon}</NavIcon>
                {route.name}
              </NavLink>
            </Item>
          ))}
          {chat && (
            <>
              <Divider className={classes.divider} />
              {chat && new Date(chat.startTime) > new Date() && (
                <a
                  className={classes.link}
                  rel="noopener noreferrer"
                  href={chatLink(chatUrl, chat, 'lander')}
                  target="_blank"
                >
                  <Item>
                    <NavIcon>
                      <Bookmark />
                    </NavIcon>
                    Lander page
                  </Item>
                </a>
              )}
              <a
                className={classes.link}
                rel="noopener noreferrer"
                href={chatLink(chatUrl, chat, 'chat')}
                target="_blank"
              >
                <Item>
                  <NavIcon>
                    <Bookmark />
                  </NavIcon>
                  Chat page
                </Item>
              </a>
              <a
                className={classes.link}
                rel="noopener noreferrer"
                href={chatLink(chatUrl, chat, 'starred')}
                target="_blank"
              >
                <Item>
                  <NavIcon>
                    <Bookmark />
                  </NavIcon>
                  Starred messages
                </Item>
              </a>
              <a
                className={classes.link}
                rel="noopener noreferrer"
                href={`yle://Chat?topic=${chat.id}`}
                target="_blank"
              >
                <Item>
                  <NavIcon>
                    <Bookmark />
                  </NavIcon>
                  Yle App route
                </Item>
              </a>
            </>
          )}
        </>
      </List>
    </>
  )

  return (
    <>
      <Drawer
        open={isOpen}
        classes={{ paper: classes.paper }}
        variant="temporary"
        className={classes.drawer}
        sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        classes={{ paper: classes.paper }}
        variant="permanent"
        className={classes.drawer}
        sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
      >
        {drawerContent}
      </Drawer>
    </>
  )
}

SideDrawer.defaultProps = {
  chat: null
}

SideDrawer.propTypes = {
  chat: chatProps,
  isOpen: bool.isRequired
}

function chatLink(chatUrl, chat, linkTo) {
  if (!chat) {
    return null
  }
  const chatPath = chat.path ? chat.path : chat.id

  const params = (linkType => {
    switch (linkType) {
      case 'chat':
        return new Date(chat.startTime) > new Date() ? '?startTime=now' : ''
      case 'starred':
        return '?startTime=now&label=starred'
      case 'lander':
        return ''
      default:
        return ''
    }
  })(linkTo)

  return `${chatUrl}/${chatPath}${params}`
}
