import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  COMMENT_POST_MODERATE_REQUEST,
  COMMENT_TOGGLE_STARRED_REQUEST,
  COMMENTS_GET_MODERATED_REQUEST,
  UPDATE_REJECT_REASON_REQUEST
} from '../actions/action-types'
import { configSelector } from '../selectors/config-selector'
import {
  getModeratedCommentsErrorAction,
  getModeratedCommentsSuccessAction,
  getPostModerateCommentErrorAction,
  getPostModerateCommentSuccessAction,
  getToggleStarredCommentSuccessAction,
  updateRejectReasonErrorAction,
  updateRejectReasonSuccessAction
} from '../actions/comments-actions'
import {
  acceptComment,
  getModeratedComments,
  labelComment,
  rejectComment,
  updateRejectReason
} from '../api/backend-api'
import { enqueueSuccessSnackbarAction } from '../actions/snackbar-actions'
import { starredLabel } from '../constants/comment-labels'

export function* watchGetModeratedComments() {
  yield takeLatest(COMMENTS_GET_MODERATED_REQUEST, getModeratedCommentsSaga)
}

export function* getModeratedCommentsSaga({ chatId, params }) {
  try {
    const config = yield select(configSelector)
    const comments = yield getModeratedComments(config, chatId, params)
    yield put(getModeratedCommentsSuccessAction({ comments, params }))
    if (params.offset > 0 && comments.length === 0) {
      yield put(enqueueSuccessSnackbarAction({ message: 'No more comments to load' }))
    }
  } catch (error) {
    yield put(getModeratedCommentsErrorAction({ error }))
  }
}

/* Performs the same backend call as moderating a comment in the moderation page,
   but side effects are different.
*/
export function* watchPostModerateCommentSaga() {
  yield takeEvery(COMMENT_POST_MODERATE_REQUEST, postModerateCommentSaga)
}

function* postModerateCommentSaga({ chatId, commentId, action, reason }) {
  try {
    const config = yield select(configSelector)
    if (action === 'accept') {
      yield acceptComment(config, chatId, commentId)
    } else {
      yield rejectComment(config, chatId, commentId, reason)
    }
    yield put(getPostModerateCommentSuccessAction({ commentId }))
  } catch (error) {
    yield put(getPostModerateCommentErrorAction({ error, commentId }))
  }
}

export function* watchToggleStarredSaga() {
  yield takeEvery(COMMENT_TOGGLE_STARRED_REQUEST, toggleStarredSaga)
}

function* toggleStarredSaga({ chatId, commentId, isStarred }) {
  try {
    const config = yield select(configSelector)
    const labels = isStarred ? [starredLabel] : []
    const comment = yield labelComment(config, chatId, commentId, labels)
    yield put(getToggleStarredCommentSuccessAction({ comment }))
  } catch (error) {
    yield put(getPostModerateCommentErrorAction({ error, commentId }))
  }
}

export function* watchUpdateRejectReasonSaga() {
  yield takeEvery(UPDATE_REJECT_REASON_REQUEST, updateRejectReasonSaga)
}

function* updateRejectReasonSaga({ chatId, commentId, reason }) {
  try {
    const config = yield select(configSelector)
    yield updateRejectReason(config, chatId, commentId, reason)
    yield put(updateRejectReasonSuccessAction({ chatId, commentId, reason }))
  } catch (error) {
    yield put(updateRejectReasonErrorAction({ error, commentId }))
  }
}
