import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { settingsSelector } from '../../selectors/settings-selector'
import { setLanguageAction, toggleShowSettingsModalAction } from '../../actions/settings-actions'

export function SettingsDialog() {
  const { show, language } = useSelector(settingsSelector)
  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(toggleShowSettingsModalAction())
  }

  const onChangeLanguage = event => {
    dispatch(setLanguageAction({ language: event.target.value }))
  }

  return (
    <Dialog open={show} onClose={onClose}>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <FormControl sx={{ marginTop: 1 }} fullWidth>
          <InputLabel id="language-select-label">Language</InputLabel>
          <Select
            labelId="language-select-label"
            id="demo-simple-select"
            value={language}
            label="Language"
            onChange={onChangeLanguage}
          >
            <MenuItem value="fi">Finnish</MenuItem>
            <MenuItem value="sv">Swedish</MenuItem>
          </Select>
          <FormHelperText>
            Language settings currently only impact DSA rejection reasons.
          </FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
