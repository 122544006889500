import {
  CLOSE_NOTICES_REQUEST,
  CLOSE_NOTICES_SUCCESS,
  GET_FLAGGED_COUNTS_REQUEST,
  GET_FLAGGED_COUNTS_SUCCESS,
  GET_TOPIC_FLAGGED_REQUEST,
  GET_TOPIC_FLAGGED_SUCCESS,
  REJECT_COMMENT_WITH_NOTICES_REQUEST,
  REJECT_COMMENT_WITH_NOTICES_SUCCESS
} from "./action-types"

export function getFlaggedCountsAction() {
  return {
    type: GET_FLAGGED_COUNTS_REQUEST
  }
}

export function getFlaggedCountsSuccessAction({ counts }) {
  return {
    type: GET_FLAGGED_COUNTS_SUCCESS,
    counts
  }
}

export function getTopicFlaggedAction({ chatId }) {
  return {
    type: GET_TOPIC_FLAGGED_REQUEST,
    chatId
  }
}

export function getTopicFlaggedSuccessAction({ chatId, flagged }) {
  return {
    type: GET_TOPIC_FLAGGED_SUCCESS,
    chatId,
    flagged
  }
}

export function closeNoticesAction({ chatId, commentId, noticeIds }) {
  return {
    type: CLOSE_NOTICES_REQUEST,
    chatId,
    commentId,
    noticeIds
  }
}

export function closeNoticesSuccessAction({ chatId, commentId }) {
  return {
    type: CLOSE_NOTICES_SUCCESS,
    chatId,
    commentId
  }
}

export function rejectCommentWithNoticesAction({ chatId, commentId, reason }) {
  return {
    type: REJECT_COMMENT_WITH_NOTICES_REQUEST,
    chatId,
    commentId,
    reason
  }
}

export function rejectCommentWithNoticesSuccessAction({ chatId, commentId }) {
  return {
    type: REJECT_COMMENT_WITH_NOTICES_SUCCESS,
    chatId,
    commentId
  }
}
