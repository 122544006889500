import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { bool, arrayOf } from 'prop-types'
import { Check } from '@mui/icons-material'
import { Button } from '@mui/material'
import { bannedUserProps, tableActionsProps } from '../prop-types'
import { unbanUserRequestAction } from '../../actions/banned-users-actions'
import { RemoveUserDialog } from '../UserList/RemoveUserDialog'
import { PrevNextButtons, Table, TableColumn } from '../Table/Table'
import { Banner, BannerText } from '../Banner/Banner'

export function BannedUsersList({ bannedUsers, isLoading, isUpdating, tableActions }) {
  const dispatch = useDispatch()
  const [enrichedBannedUsers, setEnrichedBannedUsers] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const selectedUsers = enrichedBannedUsers.filter(u => u.isSelected)

  useEffect(() => {
    setEnrichedBannedUsers(bannedUsers.map(u => ({ ...u, isSelected: false })))
  }, [bannedUsers])

  const unban = () => {
    dispatch(
      unbanUserRequestAction({
        userNicks: selectedUsers.map(u => u.nick),
        closeDialog: () => setIsDialogOpen(false)
      })
    )
  }
  const onSelectUser = user => {
    setEnrichedBannedUsers(
      enrichedBannedUsers.map(u => (u.id === user.id ? { ...u, isSelected: !u.isSelected } : u))
    )
  }
  const onSelectUsers = isChecked => {
    setEnrichedBannedUsers(enrichedBannedUsers.map(u => ({ ...u, isSelected: isChecked })))
  }

  return (
    <>
      <Banner>
        <BannerText>{`${selectedUsers.length} items selected`}</BannerText>
        <Button
          disabled={selectedUsers.length < 1}
          startIcon={<Check />}
          variant="contained"
          onClick={() => setIsDialogOpen(true)}
        >
          Unban
        </Button>
      </Banner>
      <Table
        data={enrichedBannedUsers}
        columns={[
          TableColumn.checkbox({
            onChange: onSelectUser,
            onChangeHeader: onSelectUsers,
            key: 'isSelected'
          }),
          TableColumn.userNick,
          TableColumn.banModerator,
          TableColumn.banTime,
          TableColumn.banReason,
          TableColumn.banDescription
        ]}
        isLoading={isLoading}
      />
      <PrevNextButtons {...tableActions} />
      {isDialogOpen && (
        <RemoveUserDialog
          isOpen={isDialogOpen}
          handleClose={() => setIsDialogOpen(false)}
          handleRemove={unban}
          isUpdating={isUpdating}
          nick={selectedUsers.map(u => u.nick).join(', ')}
          title="Unban users?"
          removeText="unban"
        />
      )}
    </>
  )
}

BannedUsersList.defaultProps = {
  bannedUsers: [],
  isLoading: false,
  isUpdating: false
}

BannedUsersList.propTypes = {
  bannedUsers: arrayOf(bannedUserProps),
  isLoading: bool,
  isUpdating: bool,
  tableActions: tableActionsProps.isRequired
}
