/* istanbul ignore file */

import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ChatListPage } from './pages/ChatListPage'
import { BannedUsersPage } from './pages/BannedUsersPage'
import { LoginPage } from './pages/LoginPage'
import { userSelector } from './selectors/user-selector'
import { routes } from './constants/routes'
import { ChatPage } from './pages/ChatPage'
import { UmkPage } from './pages/UmkPage'
import { UsersPage } from './pages/UsersPage'
import { FlaggedPage } from './pages/FlaggedPage'

export function Routes() {
  const user = useSelector(userSelector)
  return user.loggedIn ? <AuthRoutes /> : <LoginPage />
}

function AuthRoutes() {
  return (
    <Switch>
      <Route path="/umk/:chatId" exact component={UmkPage} />
      <Route path="/" exact component={ChatListPage} />
      <Route path={routes.chatList} component={ChatListPage} />
      <Route path={routes.users} component={UsersPage} />
      <Route path={routes.bannedUsersList} component={BannedUsersPage} />
      <Route path={routes.flagged} component={FlaggedPage} />
      <Route path={routes.chat} component={ChatPage} />
      <Redirect to="/" />
    </Switch>
  )
}
