// This default is mostly useful in tests
const defaultConfig = {
  name: 'Yle Chat Admin',
  isProd: false,
  isTest: true,
  apiAppId: '',
  apiAppKey: '',
  livefeedBackendUrl: '',
  imsUrl: '',
  yleLogoId: '',
  imagesApiUrl: ''
}

const config = window.__CLIENT_CONFIG__ || defaultConfig

export const initialState = {
  chats: {
    isLoading: false,
    isUpdating: false,
    chats: [],
    currentChat: undefined,
    writerIds: [],
    writerEmails: []
  },
  polls: {
    isLoading: false,
    isUpdating: false,
    chatId: null,
    polls: []
  },
  stats: {
    isLoading: false,
    chatId: null,
    stats: {}
  },
  settings: {
    show: false,
    language: 'fi'
  },
  config: {
    ...config,
    retryDelay: 2000
  },
  user: {
    user: { loggedIn: false },
    isLoading: true
  },
  snackbar: {},
  moderate: {
    comments: []
  },
  comments: {
    isLoading: false,
    updatingIds: [],
    comments: [],
    radioButtonAccepted: true,
    counts: {
      accepted: 0,
      rejected: 0,
      starred: 0
    }
  },
  banned: {
    bannedUsersList: [],
    isUpdating: false,
    isLoading: false
  },
  userList: {
    users: [],
    isUpdating: false,
    isLoading: false
  },
  emoji: {
    emojis: null,
    isLoading: false
  },
  flagged: {
    counts: null,
    flagged: []
  },
}
