import React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { REJECT_REASONS } from '../../utils/reject-reasons'
import { useSelector } from 'react-redux'
import { settingsSelector } from '../../selectors/settings-selector'

export function RejectButton({ id, text, onReject }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { language } = useSelector(settingsSelector);
  const open = Boolean(anchorEl);
  const onClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id={id}
        aria-controls={open ? `${id}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        color="error"
        disableElevation
        onClick={onClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {text}
      </Button>
      <Menu
        id={`${id}-menu`}
        MenuListProps={{
          'aria-labelledby': id,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        {Object.keys(REJECT_REASONS(language))
        .filter(key => !['TOP_COMMENT_REJECTED', 'BATCH_REJECTED'].includes(key))
        .map(key => (
          <MenuItem onClick={() => onReject(key)} dense key={key} value={key}>
            {REJECT_REASONS(language)[key].long}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
