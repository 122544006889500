import { Avatar, Menu, MenuItem, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'

import * as propTypes from '../prop-types'
import { userLogoutRequestAction } from '../../actions/user-actions'
import { UserIcon } from './UserIcon'
import { toggleShowSettingsModalAction } from '../../actions/settings-actions'

const useStyles = makeStyles(theme => ({
  avatar: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(2)
  }
}))

export function GoogleAvatar({ user }) {
  const theme = useTheme()
  const classes = useStyles()

  return user.loggedIn ? (
    <LoggedInAvatar user={user} classes={classes} />
  ) : (
    <Avatar className={classes.avatar}>
      <UserIcon htmlColor={theme.palette.primary.main} />
    </Avatar>
  )
}

function LoggedInAvatar({ user, classes }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch(userLogoutRequestAction())
  }

  const handleShowSettings = () => {
    dispatch(toggleShowSettingsModalAction())
  }

  return (
    <>
      <Avatar
        alt={`${user.firstName} ${user.lastName}`}
        src={user.photoUrl}
        className={classes.avatar}
        onClick={handleClick}
      />
      <Menu
        id="logout-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
        <MenuItem onClick={handleShowSettings}>Settings</MenuItem>
      </Menu>
    </>
  )
}

GoogleAvatar.propTypes = {
  user: propTypes.userProps.isRequired
}

LoggedInAvatar.propTypes = {
  user: propTypes.userProps.isRequired,
  classes: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    avatar: PropTypes.any
  }).isRequired
}
