import React, { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { func, number, string, bool } from 'prop-types'
import { FormControlLabel, Paper, Switch, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { moderationStateProps } from '../prop-types'
import { StartStop } from './StartStop'
import { RejectAll } from './RejectAll'
import { ResetButton } from './ResetButton'
import { ModerationStatus } from './ModerationStatus'
import { AcceptAll } from './AcceptAll'
import { TooltipIcon } from '../TooltipIcon'
import { toggleAutomoderationAction } from '../../actions/chats-actions'

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  flexWrap: 'wrap'
})

const Margin = styled('div')(({ theme }) => ({
  margin: theme.spacing(0.5)
}))

const Actions = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2)
}))

const Caption = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1)
}))

export function TopBar({ state, isAutomoderated, chatId, setIsModalOpen, numberOfComments }) {
  const [isModerating, setIsModerating] = useState(false)
  const [isAutomoderationDisabled, setIsAutomoderationDisabled] = useState(false)
  const dispatch = useDispatch()

  const onSwitchAutomoderation = e => {
    setIsAutomoderationDisabled(true)
    dispatch(toggleAutomoderationAction({ chatId, isAutomoderated: e.target.checked }))
  }

  useEffect(() => {
    if (isAutomoderationDisabled) {
      setIsAutomoderationDisabled(false)
    }
  }, [isAutomoderated])

  if (state) {
    return (
      <Row>
        <Margin>
          <ModerationStatus moderationStatus={state} />
        </Margin>
        <Margin>
          <Actions>
            <Caption variant="caption">
              Actions
              <div style={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  disabled={isAutomoderationDisabled}
                  control={<Switch checked={isAutomoderated} onClick={onSwitchAutomoderation} />}
                  label="Auto Reject"
                />
                <TooltipIcon
                  content="Automatically rejects incoming messages using artificial intelligence (AI).
                          Note: AI moderation operates on a separate queue from your manual moderation. This setting is global and will affect all moderators."
                />
              </div>
            </Caption>
            <Row>
              <Margin>
                <StartStop
                  chatId={chatId}
                  isModerating={isModerating}
                  setIsModerating={setIsModerating}
                />
              </Margin>
              <Margin>
                <RejectAll chatId={chatId} isDisabled={numberOfComments === 0 || !isModerating} />
              </Margin>
              <Margin>
                <AcceptAll chatId={chatId} isDisabled={numberOfComments === 0 || !isModerating} />
              </Margin>
              <Margin>
                <ResetButton setIsModalOpen={setIsModalOpen} />
              </Margin>
            </Row>
          </Actions>
        </Margin>
      </Row>
    )
  }
  return <Row />
}

TopBar.defaultProps = {
  state: null,
  chatId: null
}

TopBar.propTypes = {
  state: moderationStateProps,
  chatId: string,
  setIsModalOpen: func.isRequired,
  numberOfComments: number.isRequired,
  isAutomoderated: bool.isRequired
}
