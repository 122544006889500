import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import { func, bool, string } from 'prop-types'
import { useSelector } from 'react-redux'
import { commentProps } from '../prop-types'
import { settingsSelector } from '../../selectors/settings-selector'
import { REJECT_REASONS } from '../../utils/reject-reasons'

export function ConfirmationModal({
  title,
  showRejectReasonDropdown,
  isOpen,
  onSubmit,
  onClose,
  comment
}) {
  const [reason, setReason] = useState('')
  const { language } = useSelector(settingsSelector)

  useEffect(() => {
    setReason(comment?.rejectReason || '')
  }, [comment])

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{`${comment?.author}: ${comment?.content}`}</DialogContentText>
        {showRejectReasonDropdown && (
          <FormControl sx={{ marginTop: 1 }} size="small" fullWidth>
            <InputLabel id="id-modal-reason-select">Select reject reason</InputLabel>
            <Select
              labelId="id-modal-reason-select"
              label="Select reject reason"
              value={reason}
              onChange={e => setReason(e.target.value)}
            >
              {Object.keys(REJECT_REASONS(language)).map(key => (
                <MenuItem dense key={key} value={key}>
                  {REJECT_REASONS(language)[key].long}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={showRejectReasonDropdown && !reason}
          color="primary"
          onClick={() => onSubmit(reason)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationModal.defaultProps = {
  comment: null
}

ConfirmationModal.propTypes = {
  title: string.isRequired,
  isOpen: bool.isRequired,
  showRejectReasonDropdown: bool.isRequired,
  onSubmit: func.isRequired,
  onClose: func.isRequired,
  comment: commentProps
}
