import { put, takeLatest } from 'redux-saga/effects'
import { SETTINGS_SET_LANGUAGE } from '../actions/action-types'
import { setLanguageAction } from '../actions/settings-actions'

const LANGUAGE_KEY = 'chat-admin/settings/language'

export function* watchChangeLanguage() {
  try {
    const language = window.localStorage.getItem(LANGUAGE_KEY)
    if (language) {
      yield put(setLanguageAction({ language }))
    }
  } catch (e) {
    // No action needed
  }

  yield takeLatest(SETTINGS_SET_LANGUAGE, saveLanguageToLocalStorage)
}

function saveLanguageToLocalStorage({ language }) {
  try {
    window.localStorage.setItem(LANGUAGE_KEY, language || 'fi')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to save language to local storage', e)
  }
}
