import { put, select, takeLatest, takeEvery } from 'redux-saga/effects'
import {
  CLOSE_NOTICES_REQUEST,
  GET_FLAGGED_COUNTS_REQUEST,
  GET_TOPIC_FLAGGED_REQUEST,
  LOGIN_SUCCESS,
  REJECT_COMMENT_WITH_NOTICES_REQUEST
} from '../actions/action-types'
import { closeNotices, getFlagged, getFlaggedCounts, rejectComment } from '../api/backend-api'
import { configSelector } from '../selectors/config-selector'
import {
  getFlaggedCountsSuccessAction,
  getTopicFlaggedSuccessAction,
  closeNoticesSuccessAction,
  rejectCommentWithNoticesSuccessAction
} from '../actions/flagged-actions'
import { enqueueErrorSnackbarAction } from '../actions/snackbar-actions'
import { isOperatorSelector } from '../selectors/user-selector'

export function* watchGetFlaggedCounts() {
  yield takeLatest([LOGIN_SUCCESS, GET_FLAGGED_COUNTS_REQUEST], getFlaggedCountsSaga)
}

function* getFlaggedCountsSaga() {
  try {
    const isOperator = yield select(isOperatorSelector)
    if (!isOperator) {
      return
    }
    const config = yield select(configSelector)
    const counts = yield getFlaggedCounts(config)
    yield put(getFlaggedCountsSuccessAction({ counts }))
  } catch (error) {
    yield put(enqueueErrorSnackbarAction({ error }))
  }
}

export function* watchGetFlagged() {
  yield takeEvery(GET_TOPIC_FLAGGED_REQUEST, handleGetFlagged)
}

function* handleGetFlagged({ chatId }) {
  try {
    const config = yield select(configSelector)
    const flagged = yield getFlagged(config, chatId)
    yield put(getTopicFlaggedSuccessAction({ chatId, flagged }))
  } catch (error) {
    yield put(enqueueErrorSnackbarAction({ error: 'Failed to fetch flagged' }))
  }
}

export function* watchCloseNotice() {
  yield takeEvery(CLOSE_NOTICES_REQUEST, handleCloseNotice)
}

function* handleCloseNotice({ chatId, commentId, noticeIds }) {
  try {
    const config = yield select(configSelector)
    yield closeNotices(config, chatId, noticeIds)
    yield put(closeNoticesSuccessAction({ chatId, commentId }))
  } catch (error) {
    yield put(enqueueErrorSnackbarAction({ error }))
  }
}

export function* rejectCommentWithNotices() {
  yield takeEvery(REJECT_COMMENT_WITH_NOTICES_REQUEST, handleRejectCommentWithNotices)
}

function* handleRejectCommentWithNotices({ chatId, commentId, reason }) {
  try {
    const config = yield select(configSelector)
    yield rejectComment(config, chatId, commentId, reason)
    yield put(rejectCommentWithNoticesSuccessAction({ commentId, chatId }))
  } catch (error) {
    yield put(enqueueErrorSnackbarAction({ error }))
  }
}
