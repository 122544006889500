import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { bool, func, string, arrayOf, oneOfType, node } from 'prop-types'

const useStyles = makeStyles(({ palette }) => ({
  confirmButton: {
    backgroundColor: palette.error.main,
    color: palette.error.contrastText,
    '&:hover': {
      backgroundColor: palette.error.dark
    }
  },
  field: {
    marginTop: '10px !important',
    width: '100%'
  }
}))

export function ConfirmDialog({
  open,
  onConfirm,
  onCancel,
  title,
  text,
  confirmText,
  cancelText,
  disableConfirm,
  children
}) {
  const classes = useStyles()

  return (
    <Dialog open={open} disablePortal fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text || null}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined" color="primary">
          {cancelText}
        </Button>
        <Button
          className="confirmButton"
          onClick={onConfirm}
          variant="contained"
          classes={{ root: classes.confirmButton }}
          disabled={disableConfirm}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  open: bool.isRequired,
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
  title: string.isRequired,
  text: string,
  confirmText: string.isRequired,
  cancelText: string.isRequired,
  disableConfirm: bool,
  children: oneOfType([arrayOf(node), node])
}

ConfirmDialog.defaultProps = {
  text: '',
  disableConfirm: false,
  children: []
}
