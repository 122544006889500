
import * as actions from '../actions/action-types'

export default function (state, action) {
  switch (action.type) {
    case actions.GET_FLAGGED_COUNTS_SUCCESS:
      return Object.freeze({
        ...state,
        counts: action.counts
      })

      case actions.GET_TOPIC_FLAGGED_SUCCESS: {
        const flaggedIds = action.flagged.map(({ id }) => id)
        return Object.freeze({
          ...state,
          flagged: state.flagged.filter(({ id }) => !flaggedIds.includes(id)).concat(action.flagged)
        })
      }

      case actions.REJECT_COMMENT_WITH_NOTICES_SUCCESS:
      case actions.CLOSE_NOTICES_SUCCESS:
        return Object.freeze({
          ...state,
          counts: state.counts
            .map(count => count.topicId === action.chatId
              ? { ...count, flaggedComments: count.flaggedComments - 1 }
              : count)
            .filter(({ flaggedComments }) => flaggedComments > 0),
          flagged: state.flagged.filter(({ id }) => id !== action.commentId)
        })
    default:
      return state
  }
}
