import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { Add, Chat } from '@mui/icons-material'

import { makeStyles } from '@mui/styles'
import { arrayOf, bool, func, string } from 'prop-types'
import { useSelector } from 'react-redux'
import { TableHeaderCell } from '../TableHeaderCell'
import { commentProps } from '../prop-types'
import { PageInstructions } from '../PageInstructions'
import { ConfirmationModal } from './ConfirmationModal'
import { QuoteModal } from './QuoteModal'
import {
  getPostModerateCommentRequestAction,
  getToggleStarredCommentRequestAction,
  updateRejectReasonAction
} from '../../actions/comments-actions'
import { starredLabel } from '../../constants/comment-labels'
import { FavoriteButton } from './FavoriteButton'
import { formatTimestamp } from '../../utils/date-utils'
import { moderateStateRequestAction } from '../../actions/moderate-actions'

import CopyToClipboard from './CopyToClipboard'
import { settingsSelector } from '../../selectors/settings-selector'
import { REJECT_REASONS } from '../../utils/reject-reasons'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  loadMoreButton: {
    padding: theme.spacing(2)
  },
  quoteButton: {
    color: theme.palette.action
  },
  contentCell: {
    'overflow-wrap': 'anywhere'
  },
  actionsCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1)
  }
}))

export function CommentList({
  chatId,
  comments,
  isLoading,
  updatingIds,
  accepted,
  dispatch,
  onLoadMore
}) {
  const classes = useStyles()
  const { language } = useSelector(settingsSelector)
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false)
  const [quoteModalComment, setQuoteModalComment] = useState(null)
  const [confirmationModalState, setConfirmationModalState] = useState({
    isOpen: false,
    title: '',
    showRejectReasonDropdown: false,
    comment: null,
    onSubmit: () => {}
  })

  const checkIsStarred = comment => comment.labels && comment.labels.includes(starredLabel)

  useEffect(() => {
    setConfirmationModalState(state => ({ ...state, isOpen: false }))
  }, [updatingIds])

  const onOpenQuoteModal = comment => () => {
    setQuoteModalComment(comment)
    setIsQuoteModalOpen(true)
  }

  const onChangeReason = comment => reason => {
    dispatch(updateRejectReasonAction({ chatId, commentId: comment.id, reason }))
    setConfirmationModalState(state => ({ ...state, isOpen: false }))
  }

  const onConfirmReject = comment => reason => {
    dispatch(
      getPostModerateCommentRequestAction({
        chatId,
        commentId: comment.id,
        action: 'reject',
        reason
      })
    )
    setTimeout(() => dispatch(moderateStateRequestAction({ chatId })), 1500)
  }

  const onConfirmAccept = comment => () => {
    dispatch(
      getPostModerateCommentRequestAction({
        chatId,
        commentId: comment.id,
        action: 'accept'
      })
    )
    setTimeout(() => dispatch(moderateStateRequestAction({ chatId })), 1500)
  }

  const onStarClick = comment => () => {
    dispatch(
      getToggleStarredCommentRequestAction({
        chatId,
        commentId: comment.id,
        isStarred: !checkIsStarred(comment)
      })
    )
    setTimeout(() => dispatch(moderateStateRequestAction({ chatId })), 1500)
  }

  if (isLoading) {
    return <CircularProgress />
  }

  if (!comments.length) {
    return <PageInstructions text="No comments found" />
  }

  const getAcceptOrRejectButton = comment => {
    if (accepted) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() =>
            setConfirmationModalState({
              title: 'Reject comment?',
              showRejectReasonDropdown: true,
              isOpen: true,
              comment,
              onSubmit: onConfirmReject(comment)
            })
          }
        >
          Reject
        </Button>
      )
    }
    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() =>
          setConfirmationModalState({
            title: 'Accept comment?',
            showRejectReasonDropdown: false,
            isOpen: true,
            comment,
            onSubmit: onConfirmAccept(comment)
          })
        }
      >
        Accept
      </Button>
    )
  }

  const getQuoteButton = comment => {
    return (
      <IconButton
        title="Show quote"
        size="small"
        className={classes.quoteButton}
        onClick={onOpenQuoteModal(comment)}
      >
        <Chat />
      </IconButton>
    )
  }

  return (
    <TableContainer className={classes.container}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Id</TableHeaderCell>
            <TableHeaderCell>Author</TableHeaderCell>
            <TableHeaderCell>Message time</TableHeaderCell>
            <TableHeaderCell>Moderated by</TableHeaderCell>
            <TableHeaderCell>Content</TableHeaderCell>
            <TableHeaderCell>Quote</TableHeaderCell>
            <TableHeaderCell>{accepted ? '' : 'Reject reason'}</TableHeaderCell>
            <TableHeaderCell>Actions</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {comments.map(comment => (
            <TableRow key={comment.id}>
              <TableCell>
                <CopyToClipboard text={comment.id} />
              </TableCell>
              <TableCell>{comment.author}</TableCell>
              <TableCell>
                {accepted
                  ? formatTimestamp(comment.acceptedAt, { weekday: undefined, second: '2-digit' })
                  : formatTimestamp(comment.rejectedAt, { weekday: undefined, second: '2-digit' })}
              </TableCell>
              <TableCell>{comment.moderatedBy}</TableCell>
              <TableCell className={classes.contentCell}>{comment.content}</TableCell>
              <TableCell align="center">{comment.quote && getQuoteButton(comment)}</TableCell>
              <TableCell>{REJECT_REASONS(language)[comment.rejectReason]?.long}</TableCell>
              {updatingIds.includes(comment.id) ? (
                <TableCell colSpan={2} align="center">
                  <CircularProgress size={20} />
                </TableCell>
              ) : (
                <TableCell align="center">
                  <div className={classes.actionsCell}>
                    {accepted && (
                      <FavoriteButton
                        comment={comment}
                        isUpdating={updatingIds.includes(comment.id)}
                        onClick={onStarClick(comment)}
                        checkIsStarred={checkIsStarred}
                      />
                    )}
                    {!accepted && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ whiteSpace: 'nowrap' }}
                        onClick={() =>
                          setConfirmationModalState({
                            title: 'Edit reason',
                            showRejectReasonDropdown: true,
                            isOpen: true,
                            comment,
                            onSubmit: onChangeReason(comment)
                          })
                        }
                      >
                        Edit reason
                      </Button>
                    )}
                    {getAcceptOrRejectButton(comment)}
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        className={classes.loadMoreButton}
        disabled={isLoading}
        color="primary"
        startIcon={<Add />}
        onClick={onLoadMore}
      >
        Load more
      </Button>
      <ConfirmationModal
        title={confirmationModalState.title}
        isOpen={confirmationModalState.isOpen}
        onSubmit={confirmationModalState.onSubmit}
        onClose={() => setConfirmationModalState(state => ({ ...state, isOpen: false }))}
        showRejectReasonDropdown={confirmationModalState.showRejectReasonDropdown}
        comment={confirmationModalState.comment}
      />
      <QuoteModal
        isOpen={isQuoteModalOpen}
        handleClose={() => setIsQuoteModalOpen(false)}
        comment={quoteModalComment}
      />
    </TableContainer>
  )
}

CommentList.defaultProps = {
  comments: [],
  isLoading: false,
  updatingIds: []
}

CommentList.propTypes = {
  chatId: string.isRequired,
  comments: arrayOf(commentProps),
  isLoading: bool,
  updatingIds: arrayOf(string),
  accepted: bool.isRequired,
  dispatch: func.isRequired,
  onLoadMore: func.isRequired
}
