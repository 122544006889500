import { SETTINGS_SET_LANGUAGE, SETTINGS_TOGGLE_SHOW_MODAL } from './action-types'

export function setLanguageAction({ language }) {
  return {
    type: SETTINGS_SET_LANGUAGE,
    language
  }
}

export function toggleShowSettingsModalAction() {
  return {
    type: SETTINGS_TOGGLE_SHOW_MODAL
  }
}
