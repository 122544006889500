import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { string } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { moderateStartStatePollingAction, moderateStopStatePollingAction } from '../../actions/moderate-actions'
import { FlaggedComment } from './FlaggedComment'
import { flaggedByTopicSelector, flaggedSelector } from '../../selectors/flagged-selector'
import { getTopicFlaggedAction } from '../../actions/flagged-actions'

const CommentList = styled('div')(({ theme }) => ({
  margin: '0 auto',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '1000px',
}))

const Container = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%'
}))

/** Chat level flagged comments starts moderation state polling */
export function ChatFlagged({ chat }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(moderateStartStatePollingAction({ chatId: chat.id }))
    return () => {
      dispatch(moderateStopStatePollingAction())
    }
  }, [chat.id])

  return (
    <Flagged chatId={chat.id} />
  )
}

export function Flagged({ chatId }) {
  const dispatch = useDispatch()
  const allFlagged = useSelector(flaggedSelector)
  const flagged = allFlagged.filter(({ topicId }) => topicId === chatId)

  useEffect(() => {
    dispatch(getTopicFlaggedAction({ chatId }))
  }, [chatId])

  return (
    <Container>
      <CommentList>
        {flagged.map(f =>
          <FlaggedComment key={f.id} comment={f} />)}
      </CommentList>
    </Container>
  )
}

Flagged.propTypes = {
  chatId: string.isRequired
}
