import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { DSA_CATEGORIES } from '../../utils/reject-reasons'
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog'

const useStyles = makeStyles(() => ({
  confirmDialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
    marginTop: '1em'
  }
}))

export function BanDialog({ open, onCancel, onConfirm, author, language }) {
  const classes = useStyles()
  const [banReason, setBanReason] = useState('')
  const [banDescription, setBanDescription] = useState('')

  const onConfirmDialog = () => {
    onConfirm({ banReason, banDescription })
    setBanReason('')
    setBanDescription('')
  }

  return (
    <ConfirmDialog
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirmDialog}
      title={`Ban user ${author}?`}
      confirmText="Ban user"
      cancelText="Cancel"
      disableConfirm={!banReason}
    >
      <div className={classes.confirmDialogContent}>
        <FormControl fullWidth required>
          <InputLabel id="id-ban-reason-select">Reason for banning</InputLabel>
          <Select
            labelId="id-ban-reason-select"
            label="Reason for banning"
            value={banReason}
            onChange={e => setBanReason(e.target.value)}
          >
            {Object.keys(DSA_CATEGORIES(language)).map(key => (
              <MenuItem dense key={key} value={key}>
                {DSA_CATEGORIES(language)[key].long}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Details"
          multiline
          onChange={e => setBanDescription(e.target.value)}
          value={banDescription}
          minRows={2}
        />
      </div>
    </ConfirmDialog>
  )
}
