import { useEffect } from 'react'

const IGNORE_TARGETS = ['INPUT', 'TEXTAREA', 'LI']

export const useKeyboardShortcut = (isEnabled, callbacks) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      const keys = callbacks.map(([key]) => key)
      if (
        !IGNORE_TARGETS.includes(e.target.nodeName) &&
        keys.includes(e.key.toLowerCase()) &&
        e.target instanceof Element
      ) {
        const callback = callbacks.find(([key]) => key === e.key.toLowerCase())[1]
        callback()
        e.stopPropagation()
      }
    }

    if (isEnabled) {
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isEnabled, callbacks])
}
