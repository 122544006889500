export const REJECT_REASONS_FI = {
  TERMS_OR_DISCUSSION_RULES_VIOLATION: {
    long: 'Keskusteluohjeen vastainen sisältö',
    short: 'Keskusteluohjeen vastainen'
  },
  NEGATIVE_EFFECTS_ON_CIVIC_DISCOURSE_OR_ELECTIONS: {
    long: 'Kielteiset vaikutukset kansalaiskeskusteluun tai vaaleihin',
    short: 'Kans.keskustelun häirintä'
  },
  ILLEGAL_OR_HARMFUL_SPEECH: {
    long: 'Laiton tai haitallinen puhe',
    short: 'Haitallinen puhe'
  },
  VIOLENCE: { long: 'Väkivalta', short: 'Väkivalta' },
  CYBER_VIOLENCE: { long: 'Verkkoväkivalta', short: 'Verkkoväkivalta' },
  CYBER_VIOLENCE_AGAINST_WOMEN: {
    long: 'Naisiin kohdistuva verkkoväkivalta',
    short: 'Verkkoväkivalta (naiset)'
  },
  CONSUMER_INFORMATION: { long: 'Kuluttajatietoja koskevat rikkomukset', short: 'Kuluttajatieto' },
  SELF_HARM: { long: 'Itsensä vahingoittaminen', short: 'Itsemurhapuhe' },
  ANIMAL_WELFARE: { long: 'Eläinten hyvinvointi', short: 'Eläinten hyvinvointi' },
  DATA_PROTECTION_AND_PRIVACY_VIOLATIONS: {
    long: 'Tietosuojan ja yksityisyyden loukkaukset',
    short: 'Tietosuoja / yksityisyys'
  },
  INTELLECTUAL_PROPERTY_INFRINGEMENTS: {
    long: 'Teollis- ja tekijänoikeuksien loukkaukset',
    short: 'Tekijänoikeudet'
  },
  PROTECTION_OF_MINORS: { long: 'Alaikäisten suojelu', short: 'Alaikäisten suojelu' },
  RISK_FOR_PUBLIC_SECURITY: {
    long: 'Yleiseen turvallisuuteen kohdistuva riski',
    short: 'Yleinen turvallisuus'
  },
  SCAMS_AND_FRAUD: { long: 'Huijaukset ja/tai petokset' },
  UNSAFE_AND_PROHIBITED_PRODUCTS: {
    long: 'Vaaralliset, vaatimustenvastaiset tai kielletyt tuotteet',
    short: 'Vaaralliset tuotteet'
  },
  TOP_COMMENT_REJECTED: {
    long: 'Ketjun aloitusviesti hylätty',
    short: 'Ketjun aloitusviesti hylätty'
  },
  BATCH_REJECTED: { long: 'Joukkohylkäys', short: 'Joukkohylkäys' }
}

export const REJECT_REASONS_SV = {
  TERMS_OR_DISCUSSION_RULES_VIOLATION: {
    long: 'Innehåll som strider mot diskussionspolicyn',
    short: 'Diskussionspolicy'
  },
  NEGATIVE_EFFECTS_ON_CIVIC_DISCOURSE_OR_ELECTIONS: {
    long: 'Negativa effekter på det offentliga samtalet eller på val',
    short: 'Neg. effekter på samtalet'
  },
  ILLEGAL_OR_HARMFUL_SPEECH: {
    long: 'Olagliga eller skadliga yttranden',
    short: 'Skadliga yttranden'
  },
  VIOLENCE: { long: 'Våld', short: 'Våld' },
  CYBER_VIOLENCE: { long: 'Cybervåld', short: 'Cybervåld' },
  CYBER_VIOLENCE_AGAINST_WOMEN: { long: 'Cybervåld mot kvinnor', short: 'Cybervåld mot kvinnor' },
  CONSUMER_INFORMATION: {
    long: 'Överträdelse avseende konsumentinformation',
    short: 'Konsumentinformation'
  },
  SELF_HARM: { long: 'Självskadebeteenden', short: 'Självskadebeteenden' },
  ANIMAL_WELFARE: { long: 'Djurskydd', short: 'Djurskydd' },
  DATA_PROTECTION_AND_PRIVACY_VIOLATIONS: {
    long: 'Överträdelse av dataskydd och rätten till privatliv',
    short: 'Dataskydd / privatliv'
  },
  INTELLECTUAL_PROPERTY_INFRINGEMENTS: {
    long: 'Immaterialrättsintrång',
    short: 'Immaterialrättsintrång'
  },
  PROTECTION_OF_MINORS: { long: 'Skydd av minderåriga', short: 'Skydd av minderåriga' },
  RISK_FOR_PUBLIC_SECURITY: { long: 'Risk för allmän säkerhet', short: 'Allmän säkerhet' },
  SCAMS_AND_FRAUD: { long: 'Bedrägeri', short: 'Bedrägeri' },
  UNSAFE_AND_PROHIBITED_PRODUCTS: {
    long: 'Farliga eller förbjudna produkter och ämnen',
    short: 'Farliga produkter'
  },
  TOP_COMMENT_REJECTED: { long: 'Toppkommentar avvisad', short: 'Toppkommentar avvisad' },
  BATCH_REJECTED: { long: 'Massavvisning', short: 'Massavvisning' }
}

export const REJECT_REASONS = language =>
  language === 'fi' ? REJECT_REASONS_FI : REJECT_REASONS_SV

export const DSA_CATEGORIES = language => {
  const REASON = language === 'fi' ? REJECT_REASONS_FI : REJECT_REASONS_SV
  return {
    STATEMENT_CATEGORY_ANIMAL_WELFARE: REASON.ANIMAL_WELFARE,
    STATEMENT_CATEGORY_CONSUMER_INFORMATION: REASON.CONSUMER_INFORMATION,
    STATEMENT_CATEGORY_CYBER_VIOLENCE: REASON.CYBER_VIOLENCE,
    STATEMENT_CATEGORY_CYBER_VIOLENCE_AGAINST_WOMEN: REASON.CYBER_VIOLENCE_AGAINST_WOMEN,
    STATEMENT_CATEGORY_DATA_PROTECTION_AND_PRIVACY_VIOLATIONS:
      REASON.DATA_PROTECTION_AND_PRIVACY_VIOLATIONS,
    STATEMENT_CATEGORY_ILLEGAL_OR_HARMFUL_SPEECH: REASON.ILLEGAL_OR_HARMFUL_SPEECH,
    STATEMENT_CATEGORY_INTELLECTUAL_PROPERTY_INFRINGEMENTS:
      REASON.INTELLECTUAL_PROPERTY_INFRINGEMENTS,
    STATEMENT_CATEGORY_NEGATIVE_EFFECTS_ON_CIVIC_DISCOURSE_OR_ELECTIONS:
      REASON.NEGATIVE_EFFECTS_ON_CIVIC_DISCOURSE_OR_ELECTIONS,
    STATEMENT_CATEGORY_PROTECTION_OF_MINORS: REASON.PROTECTION_OF_MINORS,
    STATEMENT_CATEGORY_RISK_FOR_PUBLIC_SECURITY: REASON.RISK_FOR_PUBLIC_SECURITY,
    STATEMENT_CATEGORY_SCAMS_AND_FRAUD: REASON.SCAMS_AND_FRAUD,
    STATEMENT_CATEGORY_SELF_HARM: REASON.SELF_HARM,
    STATEMENT_CATEGORY_UNSAFE_AND_PROHIBITED_PRODUCTS: REASON.UNSAFE_AND_PROHIBITED_PRODUCTS,
    STATEMENT_CATEGORY_VIOLENCE: REASON.VIOLENCE,
    STATEMENT_CATEGORY_OTHER_VIOLATION_TC: REASON.TERMS_OR_DISCUSSION_RULES_VIOLATION
  }
}
